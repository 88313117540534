
import { defineComponent } from 'vue'
import { useDelivery } from '@/composables/useDelivery'
export default defineComponent({
    props: {
        image: {
            type: String,
            default: null,
        },
    },
    setup() {
        const { photoModal } = useDelivery()
        const closeModal = () => {
            photoModal.value.isActive = false
        }
        return { closeModal, photoModal }
    },
})
