import { reactive, toRefs } from 'vue'
import { useApi } from '@/composables/useApi'
import { useAlerts } from './useAlerts'
import { camelizeKeys, decamelizeKeys } from 'humps'
import {
    IStockStatusItem,
    ITransferCreatePayload,
    ITransferListItem,
    ITransferState,
} from '@/interfaces/ITransfers'

const { usePost, useGet } = useApi()
const { setAlert } = useAlerts()

export const useTransfers = () => {
    const state: ITransferState = reactive({
        isLoading: false,
        transferList: [],
        transferProductList: [],
        stockStatusList: [],
    })
    const createTransfer = async (payload: ITransferCreatePayload) => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status-change/create`
        state.isLoading = true
        try {
            const result = await usePost(url, decamelizeKeys(payload))
            setAlert('Wysłano')
            return result.data.data
        } catch (error: any) {
            setAlert(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchTransferList = async (page = 1, limit = 3) => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status-change?page=${page}&limit=${limit}`
        state.isLoading = true
        try {
            const result = await useGet(url)
            state.transferList = camelizeKeys(result.data.data) as unknown as ITransferListItem[]
            return result.data.data
        } catch (error: any) {
            setAlert(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchTransferItem = async (id: number) => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status-change/${id}`
        state.isLoading = true
        try {
            const result = await useGet(url)
            // state.transferProductList = camelizeKeys(
            // result.data.data,
            // ) as unknown as ITransferListItem[]
            return result.data.data
        } catch (error: any) {
            setAlert(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchStockStatusList = async () => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status/stock-status-change-types/`
        state.isLoading = true
        try {
            const result = await useGet(url)
            // state.transferProductList = camelizeKeys(
            // result.data.data,
            // ) as unknown as ITransferListItem[]
            state.stockStatusList = camelizeKeys(result.data.data) as unknown as IStockStatusItem[]
            return result.data.data
        } catch (error: any) {
            setAlert(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    return {
        ...toRefs(state),
        createTransfer,
        fetchTransferList,
        fetchTransferItem,
        fetchStockStatusList,
    }
}
