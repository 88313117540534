import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "o-header" }
const _hoisted_2 = { class: "o-header__item o-header__title" }
const _hoisted_3 = {
  key: 0,
  class: "a-title a-title--thin"
}
const _hoisted_4 = {
  key: 1,
  class: "a-text a-text--center"
}
const _hoisted_5 = { class: "a-title a-title--thin" }
const _hoisted_6 = { class: "a-title a-title--small a-title--lineHeightNormal" }
const _hoisted_7 = { class: "a-title a-title--small a-title--thin a-title--lineHeightNormal" }
const _hoisted_8 = ["selected", "value"]
const _hoisted_9 = { class: "a-title" }
const _hoisted_10 = { class: "o-header__item o-header__notifications" }
const _hoisted_11 = { class: "o-header__iconWrapper" }
const _hoisted_12 = { class: "o-header__notificationAmount" }
const _hoisted_13 = { class: "o-header__iconWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", {
      class: "o-header__item o-header__goBack",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.go(-1)))
    }, [
      (_ctx.route.meta.goBackArrow)
        ? (_openBlock(), _createBlock(_component_a_icon, {
            key: 0,
            icon: "ArrowLeft",
            class: "o-header__goBackIcon",
            width: "8",
            height: "14",
            clickable: true
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.route.meta.namePL)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.route.meta.namePL), 1))
        : (_ctx.route.meta.showDeliveryDetails && _ctx.delivery)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.delivery.destinationWarehouseDetails[0].warehouseName) + " / " + _toDisplayString(_ctx.route.params.id), 1),
              _createElementVNode("p", _hoisted_6, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.delivery.destinationWarehouseDetails[0].city), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(', ' + _ctx.fullAddress), 1)
              ])
            ]))
          : (_ctx.route.meta.showCarWashDetails && _ctx.activeCarWash && !_ctx.isLoading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("select", {
                  class: "a-input__select o-header__titleSelect",
                  name: "carWashListSelect",
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeSelectedCarWash($event)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carWashList, (carWash) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: carWash.id,
                      selected: carWash.id === _ctx.activeCarWash.id,
                      class: "a-title a-title--regular",
                      value: carWash.id
                    }, [
                      _createElementVNode("p", _hoisted_9, _toDisplayString(carWash.warehouseDetail.city) + ",", 1),
                      _createTextVNode(" " + _toDisplayString(carWash.warehouseDetail.address) + " " + _toDisplayString(carWash.warehouseDetail.address2), 1)
                    ], 8, _hoisted_8))
                  }), 128))
                ], 32),
                (_ctx.route.path.includes('/myjnie/'))
                  ? (_openBlock(), _createBlock(_component_a_icon, {
                      key: 0,
                      class: "o-header__titleSelectArrow",
                      icon: "ArrowDown",
                      clickable: true
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_router_link, { to: { name: 'Notifications' } }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("em", _hoisted_12, _toDisplayString(_ctx.notificationAmount), 1),
            _createVNode(_component_a_icon, {
              icon: "Bell",
              clickable: true
            })
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "o-header__item o-header__logout",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.logout()))
    }, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_a_icon, {
          icon: "Logout",
          clickable: true
        })
      ])
    ])
  ]))
}