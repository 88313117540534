import { ref } from 'vue'
interface coords {
    latitude: number | null
    longitude: number | null
}
const coords = ref<coords>({
    latitude: null,
    longitude: null,
})
export const useGeolocation = () => {
    const isNavigatorSupported = 'navigator' in window && 'geolocation' in navigator

    const fetchCoordinates = async () => {
        if (isNavigatorSupported) {
            navigator.geolocation.getCurrentPosition(position => {
                coords.value.latitude = position.coords.latitude
                coords.value.longitude = position.coords.longitude
            })
        }
    }

    return {
        coords,
        fetchCoordinates,
    }
}
