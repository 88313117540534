import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-photoModal__body o-deliveryDetails__photoModal" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.photoModal.isActive)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["o-photoModal__overlay", { 'o-photoModal__overlay--active': _ctx.photoModal.photo?.file }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "o-photoModal__image o-deliveryDetails__photo",
            src: _ctx.photoModal.photo?.file
          }, null, 8, _hoisted_2)
        ])
      ], 2))
    : _createCommentVNode("", true)
}