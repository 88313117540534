
import { defineComponent, onMounted, ref, watch } from 'vue'
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import MConfirm from '@/components/MConfirm.vue'
import Alert from '@/components/Alert.vue'
import PhotoModal from '@/components/PhotoModal.vue'
import { useRoute } from 'vue-router'
import { useConfirm } from '@/composables/useConfirm'
import { useDelivery } from '@/composables/useDelivery'
import { useOneSignal } from '@onesignal/onesignal-vue3'
import { useAuth } from '@/composables/useAuth'
import { useNotifications } from './composables/useNotifications'
export default defineComponent({
    components: {
        PhotoModal,
        TheFooter,
        TheHeader,
        Alert,
        MConfirm,
    },
    setup() {
        const route = useRoute()
        const { storeDetailsId } = useDelivery()
        const { isActive: isConfirmActive } = useConfirm()
        const showFooter = ref(true)
        const showHeader = ref(true)
        const oneSignal = useOneSignal()
        const { isLoggedIn, userId } = useAuth()
        const { fetchNotificationList } = useNotifications()
        const hideNavigation = () => {
            if (route.meta.isFooterHidden) {
                showFooter.value = false
            } else {
                showFooter.value = true
            }

            if (route.meta.isHeaderHidden) {
                showHeader.value = false
            } else {
                showHeader.value = true
            }
        }

        onMounted(async () => {
            await storeDetailsId(+route.params.id)
            if (route.meta.requiresAuth && isLoggedIn) {
            }
        })

        watch(route, async () => {
            hideNavigation()
            await storeDetailsId(+route.params.id)
            // start Onesignal
            if (route.meta.requiresAuth && isLoggedIn) {
                await fetchNotificationList()
                oneSignal.init({
                    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
                    safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID,
                    notifyButton: {
                        enable: false,
                    },
                    allowLocalhostAsSecureOrigin: process.env.VUE_APP_MODE === 'local',
                })
                oneSignal.setExternalUserId(`${userId.value}`)
            }
        })

        return {
            showHeader,
            showFooter,
            isConfirmActive,
        }
    },
})
