
import { computed, defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useCarWash } from '@/composables/useCarWash'
import { useDelivery } from '@/composables/useDelivery'
import { useAuth } from '@/composables/useAuth'
import { useNotifications } from '@/composables/useNotifications'
export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const { logout } = useAuth()
        const { isLoading, activeCarWash, carWashList } = useCarWash()
        const { currentDelivery: delivery } = useDelivery()
        const { notificationList } = useNotifications()
        const changeSelectedCarWash = async (event: Event) => {
            const selectedCarWashId = parseInt((event.target as HTMLInputElement).value)
            router.push({ name: 'CarWashDetails', params: { id: selectedCarWashId } })
        }

        const fullAddress = computed(
            () =>
                `${delivery.value?.destinationWarehouseDetails[0].address} ${delivery.value?.destinationWarehouseDetails[0].address2}`,
        )

        const notificationAmount = computed(
            () => notificationList.value.filter(notifiaction => !notifiaction.read).length,
        )

        return {
            isLoading,
            route,
            router,
            activeCarWash,
            carWashList,
            changeSelectedCarWash,
            logout,
            delivery,
            fullAddress,
            notificationList,
            notificationAmount,
        }
    },
})
