
import { computed, defineComponent, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { ERoles } from '@/enums/roles'
import { useDelivery } from '@/composables/useDelivery'
import { useCarWash } from '@/composables/useCarWash'
import { useTransfers } from '@/composables/useTransfers'

export default defineComponent({
    setup() {
        const { carWashList } = useCarWash()
        const { transferList, fetchTransferList } = useTransfers()
        const { role, assignRole } = useAuth()
        const router = useRouter()
        const route = useRoute()
        const { fetchDeliveryListCourier, fetchDeliveryListManager, deliveryList } = useDelivery()
        const transferListLength = computed(() => transferList.value.length)
        const carWashListLength = computed(() => carWashList.value.length)
        const deliveryListLength = computed(
            // Filter out Closed (id 5) deliveries
            () => deliveryList.value.filter(item => item.deliveryStage.stageNumber !== 5).length,
        )
        const fetchData = async () => {
            const userRole = await assignRole()
            if (userRole === ERoles.Courier) {
                await fetchDeliveryListCourier()
            } else if (userRole === ERoles.Manager) {
                await fetchDeliveryListManager()
                await fetchTransferList()
            }
        }

        onMounted(async () => {
            await fetchData()
        })

        const handleClick = (module: string) => {
            if (route.name === module) {
                router.go(0)
            } else {
                router.push({
                    name: module,
                })
            }
        }

        return {
            role,
            ERoles,
            deliveryList,
            handleClick,
            carWashListLength,
            deliveryListLength,
            transferListLength,
        }
    },
})
