import { INotification, INotificationsState } from '@/interfaces/INotifications'
import { reactive, toRefs } from 'vue'
import { useAlerts } from '@/composables/useAlerts'
import { useApi } from '@/composables/useApi'

const { usePost } = useApi()
const { setAlert } = useAlerts()

const state: INotificationsState = reactive({
    isLoading: false,
    notificationList: [],
    notificationListLimit: 25,
})

export const useNotifications = () => {
    const fetchNotificationList = async (pageNumber?: number) => {
        const url = `${process.env.VUE_APP_API}/v1/notifications`
        const options = {
            page: pageNumber || 1,
            limit: state.notificationListLimit,
            sort: 'Notifications.id',
            direction: 'desc',
        }
        try {
            if (!pageNumber) {
                state.isLoading = true
            }
            const response = await usePost(url, options)
            if (pageNumber) {
                response.data.data.forEach((item: INotification) => {
                    state.notificationList.push(item)
                })
            } else {
                state.notificationList = response.data.data
            }
            return response.data.data
        } catch (error: any) {
            setAlert(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }
    const setNotoficationAsRead = async (notificationId: number) => {
        const url = `${process.env.VUE_APP_API}/v1/notifications/read/${notificationId}`
        state.isLoading = true
        try {
            const response = await usePost(url)
            fetchNotificationList()
            return response
        } catch (error: any) {
            setAlert(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }
    return {
        ...toRefs(state),
        fetchNotificationList,
        setNotoficationAsRead,
    }
}
