
import { defineComponent } from 'vue'
import { useAlerts } from '@/composables/useAlerts'
import { useAuth } from '@/composables/useAuth'
export default defineComponent({
    setup() {
        const { messages, status } = useAlerts()
        const { isLoggedIn } = useAuth()
        return {
            messages,
            status,
            isLoggedIn,
        }
    },
})
